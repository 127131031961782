import React from 'react'
import { Logo } from '../components/logo'
import { ComingSoon, Small, SupportBox, Title, WrapLogo, Wrapper } from './home.styles'

function Home () {
  return (
    <Wrapper>
      <WrapLogo>
        <Logo />
      </WrapLogo>
      <ComingSoon>Site em construção</ComingSoon>
      <Title>Redes Sociais</Title>
      <ul>
        <li><a href="https://www.youtube.com/memorialdeamericana" target="_blank">YouTube</a></li>
        <li><a href="https://facebook.com/memorialdeamericana" target="_blank">Facebook</a></li>
        <li><a href="https://instagram.com/memorialdeamericana" target="_blank">Instagram</a></li>
      </ul>
      <Title>Agenda</Title>
      <ul>
        <li>QUARTA | 20h | Culto de Oração</li>
        <li>DOMINGO | 10h | Escola Biblica Dominical</li>
        <li>DOMINGO | 19h | Culto de Celebração</li>
      </ul>
      <Title>Apoie</Title>
      <SupportBox>
        <strong>Banco do Brasil</strong>
        <p>Agência 0319-0</p>
        <p>Conta corrente 16544-1</p>
        <p>Igreja Batista Memorial de Americana</p>
        <p>CNPJ 06.108.019/0001-32</p>
      </SupportBox>
      <SupportBox>
        <strong>PIX (chave CNPJ)</strong>
        <p>06.108.019/0001-32</p>
      </SupportBox>
      <Small>2021 - Igreja Batista Memorial de Americana</Small>
    </Wrapper>
  )
}

export default Home
