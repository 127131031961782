import styled from 'styled-components'

export const Wrapper = styled.section`
  padding: 22px;
  position: relative;

  && > ul {
    list-style: none;

    li {
      padding: 0 0 0 32px;
    }
  }

  @media screen and (min-width: 600px) {
    margin: 0 auto;
    width: 600px;
  }
`

export const ComingSoon = styled.h1`
  text-align: center;
`

export const WrapLogo = styled.div`
  height: 150px;
  margin-bottom: 32px;
  overflow: hidden;
  width: 100%
`

export const Title = styled.h1`
  border-bottom: 1px solid #000;
  font-size: 2em;
  font-weight: 900;
  margin: 16px 0;
  text-transform: uppercase;
`

export const Small = styled.small`
  text-align: center;
  font-weight: 100;
`

export const SupportBox = styled.div`
  margin: 16px 0;
`
